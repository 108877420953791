<template>
  <div ref="qsShadowHost" id="quick-search-app">
    <div ref="qsAppContainer" class="appContainer">
      <HomeFilter />
    </div>
  </div>
</template>

<script>
import HomeFilter from './components/HomeFilter.vue'

export default {
  name: 'App',
  components: {
    HomeFilter
  },
  mounted() {

  }
}
</script>
