import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/styles.scss';

const container = document.getElementById("crc-quick-search");
const shadowRoot = container.attachShadow({ mode: "open" });


const mountPoint = document.createElement('div');
shadowRoot.appendChild(mountPoint);

const host = window.location.host;
if (host.indexOf('localhost') !== -1 || host.indexOf('labs') >= 0 || host.indexOf('192.168.') >= 0) {
    window.qsmAppDomain = process.env.VUE_APP_DOMAIN_LOCAL;
    window.qsmAppCssDomain = process.env.VUE_APP_CSS_LOCAL_DOMAIN
} else if (host.indexOf('staging') !== -1) {
    window.appDomain = host;
    window.qsmAppCssDomain = process.env.VUE_APP_CSS_STAGING_DOMAIN
} else {
    window.appDomain = host;
    window.qsmAppCssDomain = process.env.VUE_APP_CSS_PRODUCTION_DOMAIN
}

// Adding app vendor styles
const appVendorStyles = document.createElement('link');
appVendorStyles.setAttribute('rel', 'stylesheet');
appVendorStyles.href = `${window.qsmAppCssDomain}/build-snelzoek-module/css/snelzoek-module-vendor.css`;
shadowRoot.appendChild(appVendorStyles);

// Adding app custom css to the shadow root
const appStyles = document.createElement('link');
appStyles.setAttribute('rel', 'stylesheet');
appStyles.href = `${window.qsmAppCssDomain}/build-snelzoek-module/css/snelzoek-module.css`;
shadowRoot.appendChild(appStyles);

// Setup environment-specific variables
window.qsmConnectApiKey = container.getAttribute('data-qsm-apikey');
window.qsmApiUrl = container.getAttribute('data-qsm-api') + "home-vue-endpoint/" + container.getAttribute('data-qsm-apikey');
window.qsmLayout = container.getAttribute('data-qsm-layout');
window.qsmTitle = container.getAttribute('data-qsm-title');

// Mount de Vue app op het nieuwe mount point binnen de shadow DOM
createApp(App).mount(mountPoint);
